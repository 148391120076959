body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home {
  font-family: Montserrat, sans-serif;
  letter-spacing: -0.02em;
}

.home-title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3em;
  font-weight: 700;
}

.home-text {
  font-size: 2em;
  font-weight: 500;
}

.home-btn {
  background-color: rgb(241, 181, 3);
  color: #23286b;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 10px;
}

input[type="checkbox"] {
  accent-color: #321fdb;
}

a:hover {
  cursor: pointer;
}

.scrim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top */
}

.autocomplete-container {
  position: relative;
}

.custom-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.custom-list-item {
  padding: 8px 16px;
  cursor: pointer;
}

.custom-list-item:hover {
  background-color: #f5f5f5;
}

.form-label {
  display: inline-block;
  margin-bottom: 0;
  text-align: right !important;
  font-weight: 700 !important;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  text-align: right !important;
  font-weight: 700 !important;
}

.icon-button-danger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f8f9fa;
  cursor: pointer;
  border: 1px solid transparent;
}

.icon-button-danger:hover,
.icon-button-danger:hover button,
button.icon-button-danger:hover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #e55353;
  background-color: #e55353;
  color: #fff !important;
}

.icon-button-danger button {
  border-radius: 50%;
}

.icon-button-danger:hover svg,
button.icon-button-danger:hover svg {
  color: #fff !important;
}

.sticky-header-card {
  background-color: #ffffff;
  border: none;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-transform-none {
  text-transform: none !important;
}
